.mainContent {
    background-color: #282c345a; /* Dark background color */
    color: white;
    padding: 60px 20px;
    text-align: center;
    width: 100%;
  } 

/* .roomRosterPic {
    float: left;
    width: 50%;
    height: 50%;
    object-fit: cover;
  } */
.sectionOne {
    display: flex;
    width: 100%;
    justify-content: space-around;
    /* margin: 0; */
    margin-top: 40px;
    /* background-color: aqua; */
}
.sectionOne img {
    width: 50%;
    height: 50%;
    object-fit: cover;
}
.sectionOne div {
    width: 50%;
    padding: 4rem;
}
.sectionOne p {
    width: fit-content /2;
    font-size: 1.2em;
}
.sectionTwo {
    display: flex;
    justify-content: space-around;
    /* margin: 0; */
    margin-top: 40px;
    /* background-color: aqua; */
}
.sectionTwo p {
    width: fit-content /2;
    font-size: 1.2em;
}

@media (max-width: 768px) { 
    .mainContent {
        background-color: #282c345a; /* Dark background color */
        color: white;
        padding: 1px 0px;
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      } 

    .sectionOne {
        flex-direction: column;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        width: 100%;
    }
    .sectionOne img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .sectionOne div {
        width: 100%;
        padding: 0rem;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    .sectionOne p {
        justify-self: center;
        width: 100%;
        font-size: 1.2em;
    }

    .sectionTwo {
        flex-direction: column-reverse;
        margin-top: 0px;
        width: 100%;
    }
    .sectionTwo img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .sectionTwo div {
        width: 100%;
        padding: 0rem;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    .sectionTwo p {
        justify-self: center;
        width: 100%;
        font-size: 1.2em;
    }
    
    .stats {
        flex-direction: column;
        margin-top: 20px;
    }
    
}