.contact-form {
  /* background-color: #f3f3f3; */
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.contact-form form {
  background-color: #f3f3f3;
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  
  width: 50%;
}

.contact-form label {
  margin-bottom: 10px;
  font-weight: bold;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form button {
  background-color: #555;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.contact-form button:hover {
  background-color: #666;
}

@media (max-width: 768px) { 
  .contact-form {
    padding: 0px;
    margin: 0px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
  .contact-form form {
    width: 100%;
  }
} 
