/* .hero-section {
  background-color: #282c34; 

  color: white;
  padding: 60px 20px;
  text-align: center;
} */
.hero-section h1, .hero-section p, .hero-section h2, .hero-section h3 {
  /* Text shadow for border effect */
  text-shadow: 
    -1px -1px 0 #000,  
     1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
}
.hero-section {
  color: white;
  padding: 60px 20px;
  text-align: center;
  /* background-color: #282c34; */
  position: relative;
  overflow: hidden; /* Added to contain the pseudo-element */
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/public/dark_blue_hex_grid_background.png'); /* Ensure this path is correct */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8; /* Adjust the opacity as needed */
  z-index: -1;
}

.hero-section h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 1.2em;
}
.hero-section h2 {
  margin: 0;
  font-size: 1.5em;
}
.hero-section h3 {
  margin: 0;
  font-size: 1em;
}
.stats {
  display: flex;
  justify-content: space-around;
  /* margin: 0; */
  margin-top: 40px;
  /* background-color: aqua; */
}
.roomRosterPic {
  width: 50%;
  height: 50%;
  object-fit: cover;
}
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2em; /* Smaller font size */
  }

  .hero-section p {
    font-size: 1em;
  }

  .hero-section h2 {
    font-size: 1.2em;
  }

  .hero-section h3 {
    font-size: 0.9em;
  }

  .stats {
    flex-direction: column;
    margin-top: 20px;
  }

  .roomRosterPic {
    width: 80%; /* Larger relative width */
    height: auto; /* Maintain aspect ratio */
  }
} 