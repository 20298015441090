.header {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
}
/* 
.navigation ul {
  list-style: none;
  display: flex;
}

.navigation ul li {
  margin-left: 20px;
}

.navigation ul li a {
  color: white;
  text-decoration: none;
  font-size: 1em;
}
@media (max-width: 768px) {
    .header {
      flex-direction: column;
      text-align: center;
    }
  
    .navigation ul {
      flex-direction: column;
      padding: 0;
    }
  
    .navigation ul li {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  */

  .navigation ul {
    list-style: none;
    display: flex;
  }
  
  .navigation ul li {
    margin-left: 20px;
  }
  
  .navigation ul li a {
    color: white;
    text-decoration: none;
    font-size: 1em;
  }
  
  /* Hamburger menu styles */
  .hamburger {
    display: none; /* Hidden by default */
    flex-direction: column;
    cursor: pointer;
  }
  
  .hamburger div {
    background-color: white;
    height: 2px;
    width: 25px;
    margin: 4px 0;
  }
  
  /* Mobile view styles */
  @media (max-width: 768px) {
    .mobileCorrection {
      width: 100%;
      padding: 3px 3px 3px 3px;
      display: flex;
      flex-direction: row;

      justify-content: space-between;
    }
    .header {
      flex-direction: column;
      text-align: center;
      width: 100%;
      padding: 5px 0;
    }
  
    .navigation {
      display: none; /* Hide the navigation menu initially */
    }
  
    .navigation.open {
      display: flex; /* Show the menu when open */
    }
  
    .navigation ul {
      flex-direction: column;
      padding: 0;
      width: 100%;
    }
  
    .navigation ul li {
      margin-left: 0;
      margin-top: 10px;
    }
  
    .hamburger {
      display: flex; /* Show the hamburger menu in mobile view */
    }
  } 