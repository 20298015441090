.footer {
    background-color: #222;
    color: #fff;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
  }
   
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .footer p {
    margin: 5px 0;
  }
  
  @media (max-width: 768px) { 
    .footer {
      background-color: #222;
      color: #fff;
      text-align: center;
      padding: 0px;
      position: relative;
      bottom: 0;
      padding-bottom: 10px;
      width: 100%;
    }
    .footer p {
      font-size: 0.7em;
    }
  }